import { enqueueSnackbar } from 'notistack'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import client from '~/client'
import usePageQuery from '~/client/usePageQuery'
import useMutateCompanies from '~/components/Companies/useMutateCompanies'

export default function useCompanies({ query = 'company', search } = {}) {
  const userInfo = client.getUserInfo()

  const { data, ...rest } = usePageQuery(query, { search })
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const mutate = useMutateCompanies()

  const navigate = useNavigate()

  const deleteCompany = async ({ companyId, companyName }) => {
    try {
      if (
        confirm(
          `Are you sure you want to disable ${companyName} and all its users?`
        )
      ) {
        setIsSubmitting(true)
        await client.delete('companyDetails', { companyId })
        enqueueSnackbar(`${companyName} disabled`)
        mutate()
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  const addCompany = async data => {
    setIsSubmitting(true)
    try {
      const payload = { ...data }
      if (client.hasOnlyRoles(['rep_admin', 'rep_sales_manager'])) {
        payload.companyType = payload.companyType || 'contractingFirm'
        payload.repCompanyId = userInfo.companyId
      }

      if (client.hasOnlyRoles(['distributor_company_admin'])) {
        payload.companyType = 'distributor'
        payload.distributorCompanyId = userInfo.companyId
      }

      const res = await client.put('companyDetails', payload)

      console.log('add FORM res', res)
      mutate()
      enqueueSnackbar(`${data.companyName} added`)
      navigate(-1)
    } catch (e) {
      console.error(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  return { data, mutate, isSubmitting, ...rest, addCompany, deleteCompany }
}
