import AddIcon from '@mui/icons-material/Add'
import { Stack, Typography } from '@mui/material'
import { FormContainer, useForm } from 'react-hook-form-mui'
import client from '~/client'
import useCompanies from '~/components/Companies/useCompanies'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import SubmitButton from '~/components/Data/SubmitButton'
import AddressFields from '~/routes/Admin/Company/Profile/AddressFields'
import CompanyDetailsFields from '~/routes/Admin/Company/Profile/CompanyDetailsFields'
import ContactEmails from '~/routes/Admin/Company/Profile/ContactEmails'
import ProposalSetupFields from '~/routes/Admin/ProposalSetup/Settings/ProposalSetupFields'
import AddCompanyParentSelect from './AddCompanyParentSelect'
import AddCompanyTypeSelect from './AddCompanyTypeSelect'
import { useLocation } from 'react-router-dom'

export default function AddCompanyForm({ values }) {
  const { data, addCompany, isSubmitting } = useCompanies({
    // search: { status: 'active' },
  })

  const { state } = useLocation()

  const formContext = useForm({
    defaultValues: {
      companyType: 'contractingFirm',
      ...values,
      // ...state.company,
    },
    shouldUnregister: false,
  })

  const companyType = formContext.watch('companyType')

  return (
    <>
      <Typography variant="h3" sx={{ mb: 2, mt: 3 }}>
        Add new {companyType === 'distributor' ? 'branch' : 'company'}
      </Typography>
      <FormContainer
        onSuccess={data => addCompany({ ...data, ...values })}
        formContext={formContext}
      >
        <Stack spacing={3}>
          <Typography variant="h4">Company details</Typography>
          <CompanyDetailsFields />

          <AddCompanyTypeSelect />

          <AddCompanyParentSelect />

          <Typography variant="h4">Address</Typography>
          <AddressFields />

          <Typography variant="h4">Contact emails</Typography>
          <ContactEmails />

          {client.hasRoles(['unico_admin']) &&
            ['contractingFirm', 'repCompany'].includes(companyType) && (
              <>
                <Typography variant="h4">Proposal setup</Typography>
                <ProposalSetupFields />
              </>
            )}

          <SubmitButton
            // disabled={!username || !password}
            {...{ isSubmitting }}
            sx={{ flexShrink: 0, alignSelf: 'start' }}
            endIcon={<AddIcon />}
          >
            Add new company
          </SubmitButton>
        </Stack>
        <LoadingIndicators {...{ isSubmitting }} />
      </FormContainer>
    </>
  )
}
