import useSWR from 'swr'
import { formatPageQuery } from '~/client/usePageQuery'
import client from '~/client'

export default function useCompanyOptions({
  pageSize = -1,
  sort,
  ...search
} = {}) {
  // (query, pageSize, nextPageId, sort, search)
  const query = formatPageQuery(
    'company-options',
    pageSize,
    undefined,
    sort,
    search
  )

  const { data, ...response } = useSWR(query, client.fetcher)

  return { data, ...response }
}
