import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui'

import { Card, Stack, Typography } from '@mui/material'
import React from 'react'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import SubmitButton from '~/components/Data/SubmitButton'
import sharedProps from '~/components/sharedProps'
import useCompanyDetails from '~/routes/Admin/Company/Profile/useCompanyDetails'
// import { alphaChars } from '~/core/utils/regex'

export default function GlobalPercentagePrice({ companyId } = {}) {
  const { data, error, isLoading, mutate, isSubmitting, onSuccess } =
    useCompanyDetails({ companyId })

  const formContext = useForm({
    shouldUnregister: true,
    defaultValues: data,
  })

  React.useEffect(() => {
    if (data) formContext.reset(data)
  }, [data])

  return (
    <Card {...sharedProps.card}>
      <FormContainer formContext={formContext} onSuccess={onSuccess}>
        <Stack spacing={3} alignItems="flex-start">
          <Typography variant="h3">
            Contractor multiplier of Unico list price
          </Typography>
          <TextFieldElement
            required
            // fullWidth
            name="distributorMultiplier"
            label="Contractor multiplier of Unico list price"
            type="number"
            sx={{ width: '50%', minWidth: 200 }}
            // TODO: stop long numbers limit to 2!! it's really a percentage
            // rules={{ pattern: doubleDigits }}
            InputProps={{
              // startAdornment: '.',
              inputProps: {
                max: 1,
                min: 0,
              },
            }}
            rules={{
              min: { value: 0.0001, message: 'Must be greater than 0' },
              max: { value: 1, message: 'Must be less than 1' },
            }}
            helperText="For any products without a custom price specified below, this is the proportion of the Unico list price you are selling at."
          />

          <SubmitButton isSubmitting={isSubmitting} />
        </Stack>
        <LoadingIndicators {...{ isSubmitting, isLoading }} />
      </FormContainer>
    </Card>
  )
}
