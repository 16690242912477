import { Box } from '@mui/material'
export default () => null
// export default () => (
//   <Box
//     sx={{
//       bgcolor: '#47A84A',
//       borderRadius: '100%',
//       height: 200,
//       width: 200,
//       position: 'fixed',
//       top: 100,
//       right: 24,
//       pointerEvents: 'none',
//       zIndex: 1000,
//     }}
//   />
// )
