import SelectElement from '~/core/forms/SelectElement'
import { getCreateCompanyTypesByRole } from './companyTypes'

export default function AddCompanyTypeSelect(props) {
  return (
    <SelectElement
      required
      name="companyType"
      label="Company type"
      options={getCreateCompanyTypesByRole() || []}
      // options={[]}
      sx={{ width: '50%', minWidth: 200 }}
      {...props}
    />
  )
}
