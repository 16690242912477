import { AutocompleteElement, useFormContext } from 'react-hook-form-mui'
import useRepCompanies from '~/components/Companies/useRepCompanies'
import SelectElement from '~/core/forms/SelectElement'
import { makeAutocompleteElementProps } from '~/core/forms/AutocompleteElementOptions'

const RepCompaniesAutocomplete = () => {
  const { options, data } = useRepCompanies()
  return (
    <AutocompleteElement
      // required
      name="repCompanyId"
      // options={options}
      label="Rep company"
      {...makeAutocompleteElementProps(data, {
        idKey: 'companyId',
        labelKey: 'companyName',
      })}
    />
  )
}

const DistributorCompaniesAutocomplete = () => {
  const { options, data } = useRepCompanies({
    companyType: 'distributorCompany',
    status: 'active',
  })
  return (
    <AutocompleteElement
      // required
      name="distributorCompanyId"
      // options={options}
      label="Parent distributor company "
      {...makeAutocompleteElementProps(data, {
        idKey: 'companyId',
        labelKey: 'companyName',
      })}
    />
  )
}

export default function CompanyAdminFields({ companyType: propsCompanyType }) {
  const { watch } = useFormContext()
  const companyType = watch('companyType')

  return (
    <>
      <SelectElement
        required
        name="companyType"
        label="Company type"
        options={[
          { id: 'contractingFirm', label: 'Contracting firm' },
          { id: 'distributorCompany', label: 'Distributor company' },
          { id: 'distributor', label: 'Distributor' },
          { id: 'repCompany', label: 'Rep company' },
        ]}
        sx={{ width: '50%', minWidth: 200 }}
      />
      {companyType === 'contractingFirm' && <RepCompaniesAutocomplete />}
      {companyType === 'distributor' && <DistributorCompaniesAutocomplete />}
    </>
  )
}
