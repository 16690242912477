import { Delete, Image, Save } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import SubmitButton from '~/components/Data/SubmitButton'
// import StyledDropzone from '~/core/StyledDropZone'
import StyledDropzone from '~/core/uploads/StyledDropZone'
import useCompanyDetails from '~/routes/Admin/Company/Profile/useCompanyDetails'

const CDN_DOMAIN = import.meta.env.VITE_CDN_DOMAIN

const inputId = 'csv-upload'

const imageMimeTypes = ['image/png', 'image/jpeg', 'image/svg+xml']

export default function CompanyLogoUpload({ companyId }) {
  const [previewImage, setPreviewImage] = React.useState(null)
  const [file, setFile] = React.useState(null)

  const { data, isLoading, isUploading, uploadCompanyLogo, updateCompany } =
    useCompanyDetails({ companyId })

  const onFileChange = event => {
    const file = event.target.files?.[0]
    console.log('file', file)
    setFile(file || null)
  }

  React.useEffect(() => {
    if (!file?.type?.startsWith('image/')) {
      setPreviewImage(null)
      return
    }

    const reader = new FileReader()
    reader.onloadend = () => setPreviewImage(reader.result)
    reader.readAsDataURL(file)
  }, [file])

  const handleUploadClick = async () => {
    try {
      await uploadCompanyLogo(file)
      setFile(null)
      setPreviewImage(null)
    } catch (e) {
      console.log(e)
    }
  }

  const [isDeleting, setIsDeleting] = React.useState(false)
  const handleRemoveClick = async () => {
    try {
      setIsDeleting(true)
      await updateCompany({ logo: null })
    } finally {
      setIsDeleting(false)
    }
  }

  const src = previewImage ?? (data?.logo ? `${CDN_DOMAIN}${data.logo}` : null)

  return (
    <StyledDropzone
      onChange={onFileChange}
      // accept={{
      //   'text/csv': [],
      //   'application/vnd.ms-excel': [],
      // }}
      accept={imageMimeTypes.reduce(
        (all, types) => ({ ...all, [types]: [] }),
        {}
      )}
    >
      <Typography variant="h4" sx={{ mb: 2 }}>
        Company logo
      </Typography>

      <Typography sx={{ mb: 2 }}>
        Customize homeowner proposals and emails with your company’s logo.{' '}
      </Typography>

      <Typography sx={{ mb: 1 }}>
        Drag an image here or click to browse.
        {/* Accepted formats are{imageMimeTypes.map(s => s.replace('image/', '.')).join(', ')}. */}
      </Typography>

      <Box
        as="label"
        htmlFor={inputId}
        sx={{
          display: 'block',
          border: '1px solid rgba(0, 0, 0, 0.23)',
          borderRadius: 0.5,
          p: 1,
          mb: 2,
          '& img': { width: '100%', display: 'block' },
          maxWidth: 400,
          display: 'flex',
          justifyContent: 'center',
          color: '#aaa',
          '&:hover': { borderColor: 'primary.main', cursor: 'pointer' },
        }}
      >
        {src ? (
          <img src={src} alt={`Company logo`} />
        ) : (
          <Image sx={{ fontSize: 56, display: 'block' }} />
        )}
      </Box>

      <input
        id={inputId}
        name={inputId}
        hidden
        type="file"
        // accept="text/csv"
        accept={imageMimeTypes.join(',')}
        onChange={onFileChange}
        onClick={e => (e.target.value = '')}
      />

      <Typography sx={{ mb: 2 }}>
        Supported file types: .jpg .png .svg.
        <br />
        File size up to 500kb, dimensions up to 1000px.
      </Typography>

      {/* <Stack direction="row" spacing={2}>
        <label htmlFor={inputId}>
          <Button component="span" endIcon={<UploadFile />} sx={{ mr: 2 }}>
            Browse for image
          </Button>
        </label>
      </Stack> */}

      {/* {previewImage && <img src={previewImage} alt={`${inputId} preview`} />} */}

      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: 'space-between' }}
      >
        <SubmitButton
          onClick={handleUploadClick}
          disabled={!file?.type?.startsWith('image/')}
          isSubmitting={isUploading}
          endIcon={<Save />}
        >
          Save logo
        </SubmitButton>

        <SubmitButton
          color="error"
          disabled={!data?.logo}
          onClick={handleRemoveClick}
          isSubmitting={isDeleting}
          endIcon={<Delete />}
        >
          Remove logo
        </SubmitButton>
      </Stack>

      <LoadingIndicators
        isLoading={isLoading}
        isSubmitting={isUploading || isDeleting}
      />
    </StyledDropzone>
  )
}
