import client from '~/client'
import { roles } from '~/client/StackClient'

export const companyTypeOptions = [
  { name: 'contractingFirm', label: 'Contracting firm' },
  { name: 'distributorCompany', label: 'Distributor company' },
  { name: 'distributor', label: 'Distributor branch' },
  { name: 'repCompany', label: 'Rep company' },
].map(info => ({ ...info, id: info.name }))

export const getCompanyLabel = companyType =>
  companyTypeOptions.find(({ name }) => name === companyType)?.label || ''

export const companyTypes = companyTypeOptions.reduce(
  (all, companyType) => ({ ...all, [companyType.name]: companyType }),
  {}
)

export const getCreateCompanyTypesByRole = () => {
  if (client.hasRoles([roles.super_admin, roles.unico_admin]))
    return companyTypeOptions

  if (client.hasRoles([roles.rep_admin, roles.rep_sales_manager]))
    return [companyTypes.distributor, companyTypes.contractingFirm]

  if (
    client.hasRoles([
      roles.distributor_company_admin,
      roles.distributor_company_user,
    ])
  )
    return [companyTypes.distributor, companyTypes.contractingFirm]

  if (client.hasRoles([roles.distributor_admin, roles.distributor_user]))
    return [companyTypes.contractingFirm]

  return []
}
