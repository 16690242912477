import { DeleteOutline } from '@mui/icons-material'
import client from '~/client'
import AutoTable, * as Table from '~/components/Data/AutoTable'
import useUsers from '~/components/Users/useUsers'
import UpdateIcon from '@mui/icons-material/Update'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import FanIcon from '~/components/Data/FanIcon'
import SearchContainer from '~/components/Data/Filter/SearchContainer'
import UserSearchFields from '~/components/Data/Filter/UserSearchFields'
import React from 'react'

export default function UsersTable({ query, onEditClick }) {
  const [search, setSearch] = React.useState()
  const { handleDelete, isSubmitting, resendInvitation, mutate, ...response } =
    useUsers({ query, search })

  const canEdit = client.hasRoles('admin')

  const canResend = client.hasRoles([
    'contract_firm_admin',
    'rep_admin',
    'rep_sales_manager',
    'distributor_admin',
    'distributor_company_admin',
    'unico_admin',
    'super_admin',
  ])

  const userInfo = client.getUserInfo()

  console.log('UsersTable response', response)

  return (
    <>
      <SearchContainer setSearch={setSearch}>
        <UserSearchFields />
      </SearchContainer>
      <AutoTable
        {...response}
        titleField="name"
        uniqueField="email"
        columns={[
          'firstName',
          'lastName',
          'email',
          {
            breakpoints: ['sm'],
            field: 'company.companyName',
          },
          {
            field: 'inviteSentAt',
            Component: Table.DateCell,
            headerName: 'Invited',
          },
          {
            field: 'inviteAcceptedAt',
            Component: props =>
              props.data.status === 'Invited' && canResend ? (
                <Table.ButtonCell
                  {...props}
                  onClick={() => resendInvitation(props.data)}
                  startIcon={
                    isSubmitting === props.data.userId ? (
                      <FanIcon />
                    ) : (
                      <UpdateIcon />
                    )
                  }
                  disabled={isSubmitting === props.data.userId}
                  label="Resend"
                  color="warning"
                />
              ) : (
                <Table.DateCell {...props} />
              ),
            headerName: 'Joined',
          },
          ...(canResend
            ? [
                {
                  field: 'lastLoginAt',
                  Component: Table.DateCell,
                  headerName: 'Last login',
                },
              ]
            : []),
          {
            field: 'status',
            Component: Table.ChipCell,
            headerName: 'Status',
          },
          { field: 'role', Component: Table.ChipCell },
          ...(canEdit
            ? [
                {
                  field: 'edit',
                  Component: Table.EditCell,
                  onClick: onEditClick,
                },
                {
                  field: 'delete',
                  Component: props =>
                    props.data.userId !== userInfo.userId ? (
                      <Table.ButtonCell {...props} />
                    ) : (
                      <Table.TableCell />
                    ),
                  color: 'error',
                  startIcon: <DeleteOutline />,
                  label: 'Delete',
                  to: 'delete',
                },
              ]
            : []),
        ]}
      />
    </>
  )
}
